import { useContext } from "react"
import useAxios, { configure, Options } from "axios-hooks"
import { UserContext } from "../Contexts/UserContext"
import { AXIOS_CONFIG } from "../axiosConfig"
import { AxiosRequestConfig } from "axios"

export default function useWrappedAxios<T = any>(
  config: AxiosRequestConfig,
  options?: Options
) {
  const { user } = useContext(UserContext)
  configure(AXIOS_CONFIG)

  if (user && user.auth_token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${user.auth_token}`,
    }
  }

  return useAxios<T>(config, options)
}
