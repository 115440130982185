import React from "react"

export const FitogramConfig = React.createContext({ domain: "" })

export class FitogramConfigProvider extends React.Component<
  {},
  { domain: string }
> {
  constructor(props) {
    super(props)

    this.state = {
      domain: process.env.GATSBY_FITO_DOMAIN
        ? process.env.GATSBY_FITO_DOMAIN
        : "brussels-art-pole",
    }
  }

  render() {
    return (
      <FitogramConfig.Provider value={this.state}>
        {this.props.children}
      </FitogramConfig.Provider>
    )
  }
}
