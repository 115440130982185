import React, { useContext } from "react"
import { find } from "lodash"

import useWrappedAxios from "../hooks/useWrappedAxios"
import { FitogramConfig } from "./FitogramContext"

interface IUser {
  auth_token: string
  user_uuid: string
}

interface IUserContext {
  user?: IUser
  login: (user: IUser) => void
  logout: () => void
  isAdmin: boolean
}

export const UserContext = React.createContext<IUserContext>({
  user: null,
  login: null,
  logout: null,
  isAdmin: false,
})

type User = {
  auth_token: string
  user_uuid: string
}

export const UserContextProvider: React.FC<{}> = props => {
  const initialValue =
    typeof window !== "undefined"
      ? JSON.parse(window.sessionStorage.getItem("user")) || null
      : null

  const [user, setUser] = React.useState<User>(initialValue)
  const [isAdmin, setAdmin] = React.useState<boolean>(false)
  const { domain: customerDomain } = useContext(FitogramConfig)

  const [, queryAdmins] = useWrappedAxios({
    url: `/providers/${customerDomain}/managers/`,
  })

  React.useEffect(() => {
    if (user && user.auth_token) {
      queryAdmins({
        headers: { Authorization: `Bearer ${user.auth_token}` },
      }).then(({ data }: { data: { userUUID: string }[] }) => {
        if (data.length === 0) {
          setAdmin(false)
        } else if (
          data.length > 0 &&
          find(data, { userUUID: user.user_uuid })
        ) {
          setAdmin(true)
        }
      })
    }
  }, [user, queryAdmins])

  const login = (newUser: User) => {
    setUser(newUser)
  }

  const logout = () => {
    setUser(null)
  }

  React.useEffect(() => {
    if (user) {
      sessionStorage.setItem("user", JSON.stringify(user))
    }
    if (!user) {
      sessionStorage.removeItem("user")
    }
  }, [user])

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
        isAdmin,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}
