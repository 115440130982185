import React from "react"
import { UserContextProvider } from "./src/Contexts/UserContext"
import { FitogramConfigProvider } from "./src/Contexts/FitogramContext"

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  return (
    <FitogramConfigProvider>
      <UserContextProvider>{element}</UserContextProvider>
    </FitogramConfigProvider>
  )
}
